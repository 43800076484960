import React from "react";
import "../../../styles/dowpage.scss";
import { Icon, Button } from "@bytedance/mona-ui";
function DowChapingApp() {
  function dowOpenData() {
    window.open("http://dly1688.lntelliai.com/pic/DouLinYang_setup.exe");
  }
  return (
    <div className="dowpage">
      <div className="titern">
        <h1 className="riyterss">
          <Icon type="FinishIcon" className="iconetr"></Icon>
          店铺差评提醒
        </h1>
        <h1 className="riyterss">
          <Icon type="FinishIcon" className="iconetr"></Icon>店铺品质售后提醒
        </h1>
      </div>
      <Button type="primary" onClick={dowOpenData}>
        下载体验
      </Button>
    </div>
  );
}
export default DowChapingApp;
