import {
  Tag,
  Icon,
  Divider,
  Popover,
  Button,
  Drawer,
  Carousel,
  imgPreview,
  message,
  Tabs,
  Steps,
  Modal,
  Radio,
  // Popconfirm,
} from "@bytedance/mona-ui";
import axios from "axios";
import first from "../../static/first.png";
import first2 from "../../static/图片2.png";
import first3 from "../../static/图片3.png";
import first4 from "../../static/图片4.png";
import first5 from "../../static/图片5.png";
import first6 from "../../static/图片6.png";
import video from "../../static/video.mp4";
import React, { useState, useEffect, useRef } from "react";
import Customer from "../../components/Customer";
import "../../styles/home.scss";
import { useLocation, useSearchParams } from "react-router-dom";

import Dowchapingapp from "./dowchapingapp/index";

import {
  is360Browser,
  isSougouBrowser,
  is360BrowserJisu,
  isQQBrowser,
  isEdgBrowser,
} from "../../utils/checkBowser";

const ossbaseHostUrl = "https://wtplugwt.oss-cn-hangzhou.aliyuncs.com/";
const ossbaseUrl = `${ossbaseHostUrl}webimages/`;
const ossbasePackCrxUrl = `${ossbaseHostUrl}pack/dly-2.0.0.crx?t=${new Date().getTime()}`;
const ossbasePackZipUrl = `${ossbaseHostUrl}pack/dly-2.0.0.zip?t=${new Date().getTime()}`;
const Discount = "../../public/Discountcoupon";
const ossFunsImages = [
  {
    title: "抖店查看商品最终价格",
    contentItem: [
      {
        type: "image",
        url: `${ossbaseHostUrl}funsaction/convert/initconter.png`,
      },
      {
        type: "image",
        url: `${ossbaseHostUrl}funsaction/convert/outercontent.png`,
      },
      {
        type: "image",
        url: `${ossbaseHostUrl}funsaction/convert/deletercontent.png`,
      },
      {
        type: "image",
        url: `${ossbaseHostUrl}funsaction/convert/detitlenext.png`,
      },
    ],
    contentItems: [
      {
        type: "image",
        url: `${ossbaseHostUrl}funsaction/goodscopy/a.png`,
      },
      {
        type: "image",
        url: `${ossbaseHostUrl}funsaction/goodscopy/order.png`,
      },
      {
        type: "image",
        url: `${ossbaseHostUrl}funsaction/goodscopy/website.png`,
      },
      {
        type: "image",
        url: `${ossbaseHostUrl}funsaction/goodscopy/index.png`,
      },
      // {
      //   type: "image",
      //   url: `${ossbaseHostUrl}funsaction/goodscopy/details.png`,
      // },
      // {
      //   type: "image",
      //   url: `${ossbaseHostUrl}funsaction/goodscopy/detailss.png`,
      // },
      // {
      //   type: "image",
      //   url: `${ossbaseHostUrl}funsaction/goodscopy/sku.png`,
      // },
      {
        type: "image",
        url: `${ossbaseHostUrl}funsaction/goodscopy/and.png`,
      },
      {
        type: "image",
        url: `${ossbaseHostUrl}funsaction/goodscopy/keyword.png`,
      },
      {
        type: "image",
        url: `${ossbaseHostUrl}funsaction/goodscopy/ai.png`,
      },
      {
        type: "image",
        url: `${ossbaseHostUrl}funsaction/goodscopy/ais.png`,
      },
      {
        type: "image",
        url: `${ossbaseHostUrl}funsaction/goodscopy/orders.png`,
      },
      {
        type: "image",
        url: `${ossbaseHostUrl}funsaction/goodscopy/upload.png`,
      },
      {
        type: "image",
        url: `${ossbaseHostUrl}funsaction/goodscopy/uploads.png`,
      },
      {
        type: "image",
        url: `${ossbaseHostUrl}funsaction/goodscopy/logo.png`,
      },
      // {
      //   type: "image",
      //   url: `${ossbaseHostUrl}funsaction/goodscopy/skucopy.png`,
      // },
      {
        type: "image",
        url: `${ossbaseHostUrl}funsaction/goodscopy/add.png`,
      },
    ],
    contentItemsj: [
      {
        type: "image",
        url: `${ossbaseHostUrl}funsaction/Discounts/%E5%9B%BE%E7%89%872.png`,
      },
      {
        type: "image",
        url: `${ossbaseHostUrl}funsaction/Discounts/%E5%9B%BE%E7%89%871.png`,
      },
      {
        type: "image",
        url: `${ossbaseHostUrl}funsaction/Discounts/%E5%9B%BE%E7%89%874.png`,
      },
      {
        type: "image",
        url: `${ossbaseHostUrl}funsaction/Discounts/%E5%9B%BE%E7%89%873.png`,
      },
      {
        type: "image",
        url: `${ossbaseHostUrl}funsaction/Discounts/%E5%9B%BE%E7%89%876.png`,
      },
      {
        type: "image",
        url: `${ossbaseHostUrl}funsaction/Discounts/%E5%9B%BE%E7%89%877.png`,
      },
      {
        type: "image",
        url: `${ossbaseHostUrl}funsaction/Discounts/%E5%9B%BE%E7%89%878.png`,
      },
      {
        type: "image",
        url: `${ossbaseHostUrl}funsaction/Discounts/%E5%9B%BE%E7%89%879.png`,
      },
      {
        type: "image",
        url: `${ossbaseHostUrl}funsaction/Discounts/%E5%9B%BE%E7%89%8710.png`,
      },
      {
        type: "image",
        url: `${ossbaseHostUrl}funsaction/Discounts/%E5%9B%BE%E7%89%8711.png`,
      },
      {
        type: "image",
        url: `${ossbaseHostUrl}funsaction/Discounts/%E5%9B%BE%E7%89%8712.png`,
      },
      {
        type: "image",
        url: `${ossbaseHostUrl}funsaction/Discounts/%E5%9B%BE%E7%89%8713.png`,
      },
      {
        type: "image",
        url: `${ossbaseHostUrl}funsaction/Discounts/%E5%9B%BE%E7%89%8714.png`,
      },
      {
        type: "image",
        url: `${ossbaseHostUrl}funsaction/Discounts/%E5%9B%BE%E7%89%8715.png`,
      },
      {
        type: "image",
        url: `${ossbaseHostUrl}funsaction/Discounts/%E5%9B%BE%E7%89%8716.png`,
      },
      {
        type: "image",
        url: `${ossbaseHostUrl}funsaction/Discounts/%E5%9B%BE%E7%89%8717.png`,
      },
      {
        type: "image",
        url: `${ossbaseHostUrl}funsaction/Discounts/%E5%9B%BE%E7%89%8718.png`,
      },
      {
        type: "image",
        url: `${ossbaseHostUrl}funsaction/Discounts/%E5%9B%BE%E7%89%8719.png`,
      },
    ],
    aidoc: [
      {
        type: "image",
        url: `${ossbaseHostUrl}funsaction/aidoc/1.png`,
      },
      {
        type: "image",
        url: `${ossbaseHostUrl}funsaction/aidoc/2.png`,
      },
      {
        type: "image",
        url: `${ossbaseHostUrl}funsaction/aidoc/3.png`,
      },
      {
        type: "image",
        url: `${ossbaseHostUrl}funsaction/aidoc/4.png`,
      },
      {
        type: "image",
        url: `${ossbaseHostUrl}funsaction/aidoc/5.png`,
      },
      {
        type: "image",
        url: `${ossbaseHostUrl}funsaction/aidoc/6.png`,
      },
      {
        type: "image",
        url: `${ossbaseHostUrl}funsaction/aidoc/7.png`,
      },
      {
        type: "image",
        url: `${ossbaseHostUrl}funsaction/aidoc/8.png`,
      },
      {
        type: "image",
        url: `${ossbaseHostUrl}funsaction/aidoc/9.png`,
      },
      {
        type: "image",
        url: `${ossbaseHostUrl}funsaction/aidoc/10.png`,
      },
      {
        type: "image",
        url: `${ossbaseHostUrl}funsaction/aidoc/11.png`,
      },
      {
        type: "image",
        url: `${ossbaseHostUrl}funsaction/aidoc/12.png`,
      },
      {
        type: "image",
        url: `${ossbaseHostUrl}funsaction/aidoc/13.png`,
      },
      {
        type: "image",
        url: `${ossbaseHostUrl}funsaction/aidoc/14.png`,
      },
      {
        type: "image",
        url: `${ossbaseHostUrl}funsaction/aidoc/15.png`,
      },
    ],
  },
];
const download = `${ossbaseHostUrl}file/app/doulingyang.exe?t=${new Date().getTime()}`;
const tabs = [
  {
    name: "商品策略",
    value: "0",
  },
  {
    name: "商品裂变",
    value: "1",
  },
  {
    name: "优惠卷",
    value: "2",
  },
  {
    name: "AI成图",
    value: "3",
  },
];
const dowUrl = "https://doulingyang.cn/";
let showKey = false;

let FingerprintJS = "";
const fpPromise = import("https://openfpcdn.io/fingerprintjs/v4").then(
  (FingerprintJSf) => {
    FingerprintJS = FingerprintJSf;
    return FingerprintJSf.load();
  }
);
fpPromise
  .then((fp) => fp.get())
  .then((result) => {
    const { fonts, languages, audio, canvas, ...components } =
      result.components;
    const extendedComponents = {
      ...components,
      foo: { value: "whatever" },
    };
    const fingerprintId = FingerprintJS.hashComponents(extendedComponents);
    console.log("fingerprintId+++", fingerprintId);
    // upUserId(fingerprintId)
  });

function Home() {
  const [noCancelModalVisible, setNoCancelModalVisible] = useState(true);
  const [noCancelModalVisibles, setNoCancelModalVisibles] = useState(false);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const msg = searchParams.get("msg");
  let setDowContent = null;
  var [af, setaf] = useState(false);
  var [afs, setafs] = useState(false);
  function hasCookie(name) {
    const cookies = document.cookie.split("; "); // 将cookie字符串分割成数组
    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i];
      const eqPos = cookie.indexOf("=");
      const cookieName = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
      if (cookieName === name) {
        return true; // 如果找到了匹配的cookie名称，返回true
      }
    }
    return false; // 如果没有找到匹配的cookie名称，返回false
  }
  const [Isolation, setIsolation] = useState(false);
  useEffect(() => {
    var ion = searchParams.get("Isolation") == "1";
    console.log("searchParams.get(Isolation) == 1", ion);
    if (ion) {
      setIsolation(true);
    } else {
      setIsolation(false);
    }
    // console.log('searchParams.get("Isolation") == 1', Isolation);

    document.Cookie = "1";
    console.log("cookie", document.Cookie);
    // alert(af.length);
    setTimeout(() => {
      // document.cookie = "loginUrl=chrome-extension;";
      // console.log("document.cookie", hasCookie("loginUrl"));
      setafs(true);
    }, 500);
    if (hasCookie("loginUrl")) {
      setaf(true);
      setafs(true);
    } else {
      const __ = setTimeout(() => {
        if (hasCookie("loginUrl")) {
          setafs(true);
          setaf(true);
          clearTimeout(__);
        }
      }, 500);
    }
    // setTimeout(() => {

    // }, 10);
    // alert(af.length);
    var dow = localStorage.getItem("download");
    if (dow == "true" || dow == "false") {
    } else {
      localStorage.setItem("download", true);
    }

    OperatingSystemDetector();
    // 这里的代码会在组件首次渲染和每次DOM更新后执行
    // 你可以执行DOM相关操作，例如测量或操作DOM元素
    const element = document.getElementById("homeDater");
    if (element) {
      if (setDowContent) {
        clearTimeout(setDowContent);
        setDowContent = null;
      }
      setDowContent = setTimeout((_) => {
        if (!window.WT_request) {
          if (searchParams.get("isint") && searchParams.get("isint") == 1) {
            const isint = localStorage.getItem("isint");
            // console.log("2asdasdasdd222222", isint == null);
            if (isint == null) {
              localStorage.setItem("isint", true);
              setvisibles(true);
            } else {
              if (isint == "true") {
                // setNoCancelModalVisible(false);
                setvisibles(true);
              } else {
                setvisibles(false);
                // const a = localStorage.getItem("isint");
                // console.log('2222222222222localStorage.getItem("isint")', a);
                // localStorage.setItem("isint", true);
                // if (a) {
                //   // setNoCancelModalVisible(true);
                //   setvisibles(false);
                // } else {
                //   // setNoCancelModalVisible(false);
                //   setvisibles(true);
                // }
              }
            }
          }
          console.log(!window.WT_request);
          if (hasCookie("loginUrl")) {
          } else {
            message.error("请安装正版抖羚羊插件后使用！");
          }
          var a = steps[0].name;
          settitles(a);
        }
        clearTimeout(setDowContent);
        setDowContent = null;
      }, 1000);
    }

    const handleResize = () => {
      setWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    // 清除事件监听
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const [oss, seToss] = useState(true);
  const OperatingSystemDetector = () => {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;

    let os = "未知";
    // if (/windows|win32/i.test(userAgent)) {
    //   os = "Windows";
    // }
    // if (/macintosh|mac os x/i.test(userAgent)) {
    //   os = "Mac";
    // }
    var agent = navigator.userAgent.toLowerCase();
    var isMac = /macintosh|mac os x/i.test(navigator.userAgent);
    if (agent.indexOf("win32") >= 0 || agent.indexOf("wow32") >= 0) {
      os = true;
    }
    if (agent.indexOf("win64") >= 0 || agent.indexOf("wow64") >= 0) {
      os = true;
    }
    if (isMac) {
      os = false;
    }
    seToss(os);
    console.log("oss", oss == true);
  };
  if (msg && !showKey) {
    message.error(msg);
    showKey = true;
  }
  const iconList = [
    {
      icon_url: `${ossbaseUrl}chrome.svg`,
      tittag: "谷歌浏览器",
      jiaochengcontent: (
        <div className="conte">
          <div> 1、 点击下载.</div>
          <div>
            2、打开浏览器右上角的设置 “
            <img
              className="minxue"
              src={`${ossbaseUrl}diandiandian.png`}
              alt=""
            />
            ” - “更多工具” - “扩展程序”；
          </div>
          <div>
            3、扩展程序页,打开开发者模式；
            <img
              className="devicon"
              src={`${ossbaseUrl}chrome-dev.png`}
              alt=""
            />
          </div>
          <div>
            4、将上面下载的插件压缩包解压,将解压后的压缩包内的 dly_x.x.x
            目录拖进去浏览器即可安装成功
          </div>
          <div className="lockvideoinfo">
            <Button
              type="primary"
              onClick={() => {
                setShowItemUrlObj({
                  imgUrl:
                    "install_doc/gif/GOOGLE%20%E6%B5%8F%E8%A7%88%E5%99%A8.GIF",
                  videoUrl: "install_doc/vdo/%E8%B0%B7%E6%AD%8C.mp4",
                });
                setShowShipinjiaoc(true);
                setShowDgtern("谷歌浏览器");
              }}
            >
              查看动画教程
            </Button>
          </div>
          <Popover
            placement="bottom"
            content={
              <div className="njhe">
                为什么会失效：
                <br />
                下载的安装包,解压后的那个文件夹是不能删除的,删除了插件就失效了,因为谷歌、Edge
                <br />
                实际上是引用那个目录的。
                <br />
                所以建议，解压到指定目录，解压后的那个文件夹可以放到 D<br />
                盘（一个不被删除的地方），然后再用谷歌浏览器去加载 D<br />
                盘的那个抖羚羊文件夹，如有疑问联系客服。
                <br />
              </div>
            }
            title="温馨提示"
          >
            <p className="iderrconter">
              防止插件自动删除: 解决办法(谷歌、Edge){" "}
            </p>
          </Popover>
          <p className="boterng">安装好后在抖店查看使用抖羚羊功能</p>
        </div>
      ),
      dowUrl: ossbasePackZipUrl,
    },
    {
      icon_url: `${ossbaseUrl}360x.svg`,
      tittag: "360级速浏览器",
      jiaochengcontent: (
        <div className="conte">
          <div> 1、 右键-链接另存为，保存到桌面.</div>
          <div>
            2、打开浏览器任意页面,找到上一步保存到桌面的crx插件文件
            <br />
            拖入浏览器内即可安装完毕
          </div>
          <div className="lockvideoinfo">
            <Button
              type="primary"
              onClick={() => {
                setShowItemUrlObj({
                  imgUrl: "install_doc/gif/360%E6%9E%81%E9%80%9F.GIF",
                  videoUrl: "install_doc/vdo/360%E6%80%A5%E9%80%9F.mp4",
                });
                setShowShipinjiaoc(true);
                setShowDgtern("360级速浏览器");
              }}
            >
              查看动画教程
            </Button>
          </div>
          <Popover
            placement="bottom"
            content={
              <div className="njhe">
                打开 360 浏览器或者 360 极速浏览器的 “设置”，
                <br />
                搜索“扩展”两字，去掉“自动停用来源不明的扩展”的
                <br />
                勾选
              </div>
            }
            title="温馨提示"
          >
            <p className="iderrconter">
              防止插件自动删除:解决办法(360系列浏览器){" "}
            </p>
          </Popover>
          <p className="boterng">安装好后在抖店查看使用抖羚羊功能</p>
        </div>
      ),
      dowUrl: ossbasePackCrxUrl,
    },
    {
      icon_url: `${ossbaseUrl}360-s.svg`,
      tittag: "360安全浏览器",
      jiaochengcontent: (
        <div className="conte">
          <div> 1、 右键-链接另存为，保存到桌面.</div>
          <div>
            2、打开浏览器任意页面,找到上一步保存到桌面的crx插件文件
            <br />
            拖入浏览器内即可安装完毕
          </div>

          <div className="lockvideoinfo">
            <Button
              type="primary"
              onClick={() => {
                setShowItemUrlObj({
                  imgUrl: "install_doc/gif/360%E5%AE%89%E5%85%A8.GIF",
                  videoUrl: "install_doc/vdo/360%E5%AE%89%E5%85%A8.mp4",
                });
                setShowShipinjiaoc(true);
                setShowDgtern("360安全浏览器");
              }}
            >
              查看动画教程
            </Button>
          </div>
          <Popover
            placement="bottom"
            content={
              <div className="njhe">
                打开 360 浏览器或者 360 极速浏览器的 “设置”，
                <br />
                搜索“扩展”两字，去掉“自动停用来源不明的扩展”的
                <br />
                勾选
              </div>
            }
            title="温馨提示"
          >
            <p className="iderrconter">
              防止插件自动删除:解决办法(360系列浏览器){" "}
            </p>
          </Popover>
          <p className="boterng">安装好后在抖店查看使用抖羚羊功能</p>
        </div>
      ),
      dowUrl: ossbasePackCrxUrl,
    },
    {
      icon_url: `${ossbaseUrl}sougou.svg`,
      tittag: "搜狗浏览器",
      jiaochengcontent: (
        <div className="conte">
          <div> 1、点击下载, 选择保存到桌面.</div>
          <div>
            2、打开浏览器右上角的设置 “
            <img
              className="minxue"
              src={`${ossbaseUrl}shougouseting.png`}
              alt=""
            />
            ” - “应用中心” - “管理我的应用”；
          </div>
          <div> 3、将下载的插件拖进去浏览器即可安装成功.</div>

          <div className="lockvideoinfo">
            <Button
              type="primary"
              onClick={() => {
                setShowItemUrlObj({
                  imgUrl: "install_doc/gif/QQ%E6%B5%8F%E8%A7%88%E5%99%A8.GIF",
                  videoUrl: "install_doc/vdo/sg.mp4",
                });
                setShowShipinjiaoc(true);
                setShowDgtern("搜狗浏览器");
              }}
            >
              查看动画教程
            </Button>
          </div>

          <p className="boterng">安装好后在抖店查看使用抖羚羊功能</p>
        </div>
      ),
      dowUrl: ossbasePackCrxUrl,
    },
    {
      icon_url: `${ossbaseUrl}2345.svg`,
      tittag: "2345浏览器",
      jiaochengcontent: (
        <div className="conte">
          <div>1、 右键-目标另存为，保存到桌面.</div>
          <div>
            2、打开浏览器任意页面,找到上一步保存到桌面的crx插件文件,
            <br />
            拖入浏览器内即可安装完毕
          </div>
          <div className="lockvideoinfo">
            <Button
              type="primary"
              onClick={() => {
                setShowItemUrlObj({
                  imgUrl: "install_doc/gif/2345.GIF",
                  videoUrl: "install_doc/vdo/2345.mp4",
                });
                setShowShipinjiaoc(true);
                setShowDgtern("2345浏览器");
              }}
            >
              查看动画教程
            </Button>
          </div>

          <p className="boterng">安装好后在抖店查看使用抖羚羊功能</p>
        </div>
      ),
      dowUrl: ossbasePackCrxUrl,
    },
    {
      icon_url: `${ossbaseUrl}qq.svg`,
      tittag: "QQ浏览器",
      jiaochengcontent: (
        <div className="conte">
          <div> 1、点击下载, 选择保存到桌面.</div>
          <div>
            2、打开浏览器右上角的设置 “
            <img className="minxue" src={`${ossbaseUrl}qqseting.png`} alt="" />”
            - “应用中心” - “管理我的应用”；
          </div>
          <div> 3、将下载的插件拖进去浏览器即可安装成功.</div>

          <div className="lockvideoinfo">
            <Button
              type="primary"
              onClick={() => {
                setShowItemUrlObj({
                  imgUrl: "install_doc/gif/QQ%E6%B5%8F%E8%A7%88%E5%99%A8.GIF",
                  videoUrl: "install_doc/vdo/qq.mp4",
                });
                setShowShipinjiaoc(true);
                setShowDgtern("QQ浏览器");
              }}
            >
              查看动画教程
            </Button>
          </div>

          <p className="boterng">安装好后在抖店查看使用抖羚羊功能</p>
        </div>
      ),
      dowUrl: ossbasePackZipUrl,
    },
    {
      icon_url: `${ossbaseUrl}Edge.svg`,
      tittag: "Edge浏览器",
      jiaochengcontent: (
        <div className="conte">
          <div> 1、 点击下载.</div>
          <div>
            2、打开浏览器右上角的设置 "
            <img
              className="minxue"
              src={`${ossbaseUrl}edgeseting.png`}
              alt=""
            />
            " - "扩展" - "管理扩展";
          </div>
          <div>
            3、在扩展程序页左边, 打开
            <img className="devicon" src={`${ossbaseUrl}edge_dev.png`} alt="" />
          </div>
          <div>
            4、将上面下载的插件压缩包解压,将解压后的压缩包内的 dly_x.x.x
            目录拖进去浏览器即可安装成功
          </div>
          <div className="lockvideoinfo">
            <Button
              type="primary"
              onClick={() => {
                setShowItemUrlObj({
                  imgUrl: "install_doc/gif/EDGE%20.GIF",
                  videoUrl: "install_doc/vdo/edge.mp4",
                });
                setShowShipinjiaoc(true);
                setShowDgtern("Edge浏览器");
              }}
            >
              查看动画教程
            </Button>
          </div>
          <Popover
            placement="bottom"
            content={
              <div className="njhe">
                为什么会失效：
                <br />
                下载的安装包,解压后的那个文件夹是不能删除的,删除了插件就失效了,因为谷歌、Edge
                <br />
                实际上是引用那个目录的。
                <br />
                所以建议，解压到指定目录，解压后的那个文件夹可以放到 D<br />
                盘（一个不被删除的地方），然后再用谷歌浏览器去加载 D<br />
                盘的那个抖羚羊文件夹，如有疑问联系客服。
                <br />
              </div>
            }
            title="温馨提示"
          >
            <p className="iderrconter">
              防止插件自动删除: 解决办法(谷歌、Edge){" "}
            </p>
          </Popover>
          <p className="boterng">安装好后在抖店查看使用抖羚羊功能</p>
        </div>
      ),
      dowUrl: ossbasePackZipUrl,
    },
  ];
  let infoItem = iconList[0];
  if (is360Browser() && !is360BrowserJisu()) {
    console.log("is360BrowserJisu", is360BrowserJisu());
    console.log("是360安全版");
    // setNowWebIcon(iconList[2])
    infoItem = iconList[2];
  } else if (is360BrowserJisu()) {
    console.log("是360极速版");
    // setNowWebIcon(iconList[1])
    infoItem = iconList[1];
  } else if (isSougouBrowser()) {
    infoItem = iconList[3];
  } else if (isQQBrowser()) {
    infoItem = iconList[5];
  } else if (isEdgBrowser()) {
    infoItem = iconList[6];
  }
  const [nowWebIcon] = useState(infoItem);
  const [showShipinjiaoc, setShowShipinjiaoc] = useState(false);
  const [showDgtern, setShowDgtern] = useState("");
  const [showVideo, setShowVideo] = useState(false);
  const [showItemUrlObj, setShowItemUrlObj] = useState({
    imgUrl: "",
    videoUrl: "",
  });
  const [activeKey, setActiveKey] = useState("0");
  const onSwipes = (item, index) => {
    var as = item;
    setcurrent(as);
    if (item == ossFunsImages[0].contentItem.length - 1) {
      setTimeout(() => {
        setActiveKey("1");
        setcurrents("1");
      }, 2000);
    }
  };
  const onSwipess = (item, index) => {
    // console.log("2222222", item, ossFunsImages[0].contentItems, steps);
    var as = item;
    setcurrents(as);
    if (item == ossFunsImages[0].contentItems.length - 1) {
      setTimeout(() => {
        setActiveKey("2");
        setcurrentsj("1");
      }, 2000);
    }
  };
  const onSwipessj = (item, index) => {
    console.log(
      "2222222",
      item,
      ossFunsImages[0].contentItemsj,
      item == ossFunsImages[0].contentItemsj.length - 1
    );
    var as = item;
    setcurrentsj(as);
    if (item == ossFunsImages[0].contentItemsj.length - 1) {
      setTimeout(() => {
        setActiveKey("0");
        setcurrent("1");
      }, 2000);
    }
  };
  const onShowAiDoc = (item, index) => {
    console.log(
      "2222222",
      item,
      ossFunsImages[0].aidoc,
      item == ossFunsImages[0].aidoc.length - 1
    );
    var as = item;
    setcurrentsj(as);
    if (item == ossFunsImages[0].aidoc.length - 1) {
      setTimeout(() => {
        setActiveKey("0");
        setcurrent("1");
      }, 2000);
    }
  };
  const alltabs = (key) => {
    setActiveKey(key);
  };
  const { Step } = Steps;
  const [current, setcurrent] = useState("1");
  const [currents, setcurrents] = useState("1");
  const [currentsj, setcurrentsj] = useState("1");
  const carouselRef = useRef(null);
  const carouselRefs = useRef(null);
  const carouselRefss = useRef(null);
  const carouselRefssj = useRef(null);
  const aidocRef = useRef(null);
  const step = [
    {
      name: "点击一键转换价格",
      value: "0",
    },
    {
      name: "查看优惠卷,惊喜卷",
      value: "1",
    },
    {
      name: "商品价格转换详情",
      value: "2",
    },
    {
      name: "最终支付",
      value: "3",
    },
  ];
  const steps = [
    {
      name: "抖店搜索抖羚羊",
      value: "0",
    },
    {
      name: "立即订购",
      value: "1",
    },
    {
      name: "下载插件",
      value: "2",
    },
    {
      name: "点开裂变工具",
      value: "3",
    },
    {
      name: "商品裂变",
      value: "4",
    },
    {
      name: "添加关键词",
      value: "5",
    },
    {
      name: "ai生成",
      value: "6",
    },
    {
      name: "微调",
      value: "7",
    },
    {
      name: "调整图片顺序",
      value: "8",
    },
    {
      name: "添加边框",
      value: "9",
    },
    {
      name: "可以自己上传",
      value: "10",
    },
    {
      name: "添加图标",
      value: "11",
    },
    {
      name: "价格递增递减",
      value: "12",
    },
  ];
  const stepsj = [
    {
      name: "登录抖店后台，点击—优惠卷",
      value: "0",
    },
    {
      name: "选择优惠卷领取时间",
      value: "1",
    },
    {
      name: "选择使用的时间",
      value: "2",
    },
    {
      name: "限制日期范围",
      value: "3",
    },
    {
      name: "限制有效天数，填写领取之后的几天内可以使用",
      value: "4",
    },
    {
      name: "商品范围，全店商品",
      value: "5",
    },
    {
      name: "商品范围，指定商品",
      value: "6",
    },
    {
      name: "添加优惠券",
      value: "7",
    },
    {
      name: "优惠券立减",
      value: "8",
    },
    {
      name: "优惠卷满减",
      value: "9",
    },
    {
      name: "优惠卷-折扣",
      value: "10",
    },
    {
      name: "可以选择一个活动里放几个产品，填写活动的名称",
      value: "11",
    },
    {
      name: "选择产品范围",
      value: "12",
    },
    {
      name: "商品导入",
      value: "13",
    },
    {
      name: "选择商品，点击确定",
      value: "14",
    },
    {
      name: "选择商品，点击确定",
      value: "15",
    },
    {
      name: "选择商品，点击确定",
      value: "16",
    },

    {
      name: "点击查看优惠卷",
      value: "17",
    },
  ];
  const aidoctext = [
    {
      name: "订购使用抖羚羊",
      value: "0",
    },
    {
      name: "点击商品管理=>商品裂变",
      value: "1",
    },
    {
      name: "点击批量AI作图",
      value: "2",
    },
    {
      name: "点击生成",
      value: "3",
    },
    {
      name: "也可批量全部链接",
      value: "4",
    },
    {
      name: "选择需要修改的图片",
      value: "5",
    },
    {
      name: "可以自定义风格",
      value: "6",
    },
    {
      name: "点击生成全部链接",
      value: "7",
    },
    {
      name: "详情页可新增AI的图片",
      value: "8",
    },
    {
      name: "选择已生成的AI图片",
      value: "9",
    },
    {
      name: "点击选择需要修改的图片",
      value: "10",
    },
    {
      name: "SKU图片AI作图",
      value: "11",
    },
    {
      name: "同样点击生成",
      value: "12",
    },
    {
      name: "也可单链接生成AI图片",
      value: "13",
    },
    {
      name: "点击生成",
      value: "14",
    },
  ];
  const goStep = (item, index) => {
    console.log(item, index);
    var as = index;
    setcurrent(as);
    carouselRef.current.goTo(as, true);
  };
  const goSteps = (item, index) => {
    console.log(item, index);
    var as = index;
    setcurrents(as);
    carouselRefs.current.goTo(as, true);
  };
  const goStepsj = (item, index) => {
    console.log(item, index);
    var as = index;
    setcurrentsj(as);
    carouselRefssj.current.goTo(as, true);
  };
  const goAidocIt = (item, index) => {
    console.log(item, index);
    var as = index;
    setcurrentsj(as);
    aidocRef.current.goTo(as, true);
  };
  const [width, setWidth] = useState(window.innerWidth);

  function onChange(a) {
    var as = steps[a + 1].name;
    settitles(as);
    // console.log("2222222222", as, a);
    if (a == 11) {
      setnasme("完成");
    } else {
      setnasme("下一步");
    }
  }
  const nextPict = () => {
    settitles("...");
    if (nasme == "下一步") {
      carouselRefss.current.next();
    } else {
      setNoCancelModalVisible(false);
    }
  };
  const [titles, settitles] = useState("");
  const oncancel = () => {
    setNoCancelModalVisible(false);
    localStorage.setItem("isint", false);
  };
  const [nasme, setnasme] = useState("下一步");
  const divNext = () => {
    setNoCancelModalVisible(false);
  };
  //
  const [visibles, setvisibles] = useState(false);
  const [visiblest, setvisiblest] = useState(false);
  const PopNext = () => {
    setvisibles(false);
    setvisiblest(true);
  };
  const PopNexts = () => {
    setvisiblest(false);
    localStorage.setItem("isint", false);
  };
  const winUrl = () => {
    window.location.href = nowWebIcon.dowUrl;
    message.success("下载中");
    var dow = localStorage.getItem("download");
    if (dow == "true") {
      axios.get(`${dowUrl}api/tools/statisticsDowAppNum`).then((res) => {
        console.log(res);
        localStorage.setItem("download", false);
        // console.log(res);
      });
    } else {
    }
    // console.log(download);
  };
  const winUrls = (item) => {
    window.location.href = item.dowUrl;
    message.success("下载中");
  };
  const [visiblesd, setModalVisible] = useState(false);
  const [visiblesds, setModalVisibles] = useState(false);
  const InstallationDownload = () => {
    window.location.href = download;
    var dow = localStorage.getItem("download");
    if (dow == "true") {
      axios.get(`${dowUrl}api/tools/statisticsDowAppNum`).then((res) => {
        console.log(res);
        localStorage.setItem("download", false);
        // console.log(res);
      });
    } else {
    }
    // console.log(download);
    setModalVisible(true);
    setTimeout(() => {
      setModalVisibles(true);
    }, 5000);
    // console.log(visiblesd);
  };
  const DownloadIsolation = () => {
    setdefaultValue("2");
    setvisiblesdion(true);
    window.location.href = download;
    var dow = localStorage.getItem("download");
    if (dow == "true") {
      axios.get(`${dowUrl}api/tools/statisticsDowAppNum`).then((res) => {
        console.log(res);
        localStorage.setItem("download", false);
      });
    } else {
    }
    setfromion(false);
    setTimeout((_) => {
      setfromion(true);
      clearTimeout(_);
    }, 3000);
  };
  const handleCancel = () => {
    setModalVisible(false);
    setModalVisibles(false);
  };
  const [visiblesdion, setvisiblesdion] = useState(false);
  const videoion = (i) => {
    // if (i == "picture") {
    //   setdefaultValue("1");
    // } else if (i == "video") {
    setdefaultValue("2");
    // }
    setvisiblesdion(true);
    setfromion(false);
    setTimeout((_) => {
      setfromion(true);
      clearTimeout(_);
    }, 3000);
  };
  const handleCancels = () => {
    setvisiblesdion(false);
  };
  const ionlist = [
    {
      url: first,
      title: "登入doulingyang.cn  点击一键安装使用",
    },
    {
      url: first6,
      title: "安装完桌面有个战斧浏览器（如图）",
    },
    {
      url: first2,
      title: "双击点开点击桌面就是云桌面",
    },
    {
      url: first3,
      title: "登入注册好送你20元卷，在点击购买服务器",
    },
    {
      url: first4,
      title: "购买完等一分钟左右生成服务器，点击远程就可以实现ip桌面",
    },
    {
      url: first5,
      title: "登入你就可以下载抖店桌面端，登入主账号在上面就可以实现ip隔离",
    },
  ];

  const [defaultValue, setdefaultValue] = useState(1);
  const group = (i) => {
    setdefaultValue(i);
  };
  const [fromion, setfromion] = useState(false);
  return (
    <section className="Home" id="homeDater">
      <header className="herderbox">
        <div className="bojhher">
          <div className="leftbox">
            <div className="lefutericon">
              <img
                src={`${ossbaseUrl}logo512.png`}
                className="iconimag"
                alt="logo512.png"
              />
            </div>
            <div className="rightname">
              <h1 className="namejh">抖羚羊电商工具</h1>
              <div className="rightdiscoup">
                老板运营的得力助手
                <div className="vishops">
                  <Tag bordered transparentBg type="success">
                    最新版本：V2.0.0
                  </Tag>
                </div>
              </div>
            </div>
          </div>
          <div className="rightbox">
            <div className="btnary">
              <Popover
                content={
                  <div style={{ width: "150px" }}>
                    <h3 style={{ textAlign: "center" }}>2.去使用</h3>
                    <Button
                      type="primary"
                      style={{ marginLeft: "80px" }}
                      onClick={PopNexts}
                      size="small"
                    >
                      完成
                    </Button>
                  </div>
                }
                trigger="click"
                visible={visiblest}
                // onVisibleChange={handleVisibleChange}
              >
                <Button
                  type="primary"
                  size="small"
                  onClick={() =>
                    (window.location.href =
                      "https://fxg.jinritemai.com/ffa/g/list")
                  }
                >
                  <a>去使用</a>
                </Button>
              </Popover>
            </div>
            <div className="iconouhte funinfouer">
              <a href="#fundoe" className="animated slideInLeft">
                {" "}
                <Icon type="ServiceMarketIcon"></Icon> 功能演示
              </a>
            </div>
            {/* <div className="iconouhte rightuser">
							<Icon type="CustomerServiceIcon"></Icon>{' '}
							<span className="coneyr">联系方式</span>
							<div className="showinfos">
								<Customer />
							</div>
						</div> */}
            <div className="iconouhte rightuser">
              <Icon type="CommodityIcon"></Icon>{" "}
              <span
                className="coneyr"
                onClick={() =>
                  window.open(
                    "https://fuwu.jinritemai.com/detail?from=open_partner_svcList&service_id=25348"
                  )
                }
              >
                订购服务
              </span>
              {/* <div className="showinfos">
								<Customer />
							</div> */}
            </div>
            <Button
              style={{ margin: "12px 0px 0 10px" }}
              type="primary"
              size="small"
              onClick={() => videoion("video")}
            >
              <a>IP防隔离使用教程</a>
            </Button>
          </div>
        </div>
      </header>
      <main className="contentainbox">
        <Dowchapingapp />
        {afs == true ? (
          <div>
            {" "}
            <div>
              {af == false ? (
                <div className="titlecontent">
                  <div className="tibtitlecontent">
                    <h1 className="tibtitle">
                      抖羚羊电商工具 有效提高运营效率
                    </h1>
                    <div className="tibtitleconter">
                      专注电商运营插件，协助老板、运营人员高效准确运营
                    </div>
                  </div>
                  <Popover
                    placement="bottom"
                    content={
                      <div style={{ width: "150px" }}>
                        <h3 style={{ textAlign: "center" }}>1.点击下载插件</h3>
                        {/* <img
                  style={{ width: "100px", height: "100px" }}
                  src={ossbaseUrl + showItemUrlObj.imgUrl}
                /> */}
                        {/* <div>{nowWebIcon.jiaochengcontent}</div> */}
                        <Button
                          type="primary"
                          style={{ marginLeft: "80px" }}
                          onClick={PopNext}
                          size="small"
                        >
                          下一步
                        </Button>
                      </div>
                    }
                    // title=""
                    trigger="click"
                    visible={visibles}
                    // onVisibleChange={handleVisibleChange}
                  >
                    {Isolation == true ? (
                      <div>
                        <div className="dowIcnt">
                          <div className="Outt">官方推荐使用</div>
                          <div
                            className="dowiconboxst"
                            onClick={DownloadIsolation}
                          >
                            <div className="iconbiox">
                              <img
                                src={`${ossbaseUrl}logo512.png`}
                                style={{ width: "50px", marginTop: "5px" }}
                                alt="logo512.png"
                              />
                            </div>
                            <div
                              className="dowtitlecont"
                              style={{
                                fontWeight: "900",
                                letterSpacing: "1px",
                                letterSpacing: "4px",
                                marginLeft: "20px",
                              }}
                            >
                              IP隔离防关联使用
                            </div>
                            <div className="dowiconbx">
                              <Icon
                                type="DownloadIcon"
                                className="iconmobx"
                              ></Icon>
                            </div>
                          </div>
                        </div>
                        <Button
                          size="large"
                          type="primary"
                          onClick={() => videoion("picture")}
                        >
                          防隔离使用说明
                        </Button>
                      </div>
                    ) : (
                      <div>
                        {oss == false ? (
                          <div>
                            <Popover
                              content={nowWebIcon.jiaochengcontent}
                              title={nowWebIcon.tittag + " 版插件-安装教程"}
                            >
                              <div
                                className="dowiconbox"
                                onClick={winUrl}
                                style={{ marginBottom: "100px" }}
                              >
                                <div className="iconbiox">
                                  <img
                                    src={nowWebIcon.icon_url}
                                    className="iconmobxconte"
                                    alt=""
                                  />
                                </div>
                                <div className="dowtitlecont">
                                  {nowWebIcon.tittag}
                                </div>
                                <div className="dowiconbx">
                                  <Icon
                                    type="DownloadIcon"
                                    className="iconmobx"
                                  ></Icon>
                                </div>
                              </div>
                            </Popover>
                          </div>
                        ) : (
                          <div>
                            <div className="dowIcn">
                              <div className="Out">官方推荐使用</div>
                              <div
                                className="dowiconboxs"
                                onClick={InstallationDownload}
                              >
                                <div className="iconbiox">
                                  <img
                                    src={`${ossbaseUrl}logo512.png`}
                                    style={{ width: "50px", marginTop: "5px" }}
                                    alt="logo512.png"
                                  />
                                </div>
                                <div
                                  className="dowtitlecont"
                                  style={{
                                    fontWeight: "900",
                                    letterSpacing: "1px",
                                  }}
                                >
                                  一键安装使用
                                </div>
                                <div className="dowiconbx">
                                  <Icon
                                    type="DownloadIcon"
                                    className="iconmobx"
                                  ></Icon>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}{" "}
                      </div>
                    )}
                  </Popover>
                  {/*  */}
                  {Isolation == true ? (
                    <div>
                      <video
                        style={{ width: "100%", margin: "20px auto 0" }}
                        src={video}
                        controls
                        autoPlay
                      ></video>
                    </div>
                  ) : (
                    <>
                      <div>
                        <Divider>
                          <h3 style={{ fontWeight: "900" }}>下载插件</h3>
                        </Divider>
                        <div className="crolist">
                          {iconList.map((item, index) => {
                            return (
                              <Popover
                                title={item.tittag + " 版插件-安装教程"}
                                key={index}
                                content={item?.jiaochengcontent}
                              >
                                <div
                                  className="intoulist"
                                  onClick={() => winUrls(item)}
                                >
                                  <div className="iconimagebox">
                                    <img
                                      src={item.icon_url}
                                      className="iconmobxconte"
                                      alt=""
                                    />
                                  </div>
                                  <div className="contenttitle">
                                    {item.tittag}
                                  </div>
                                </div>
                              </Popover>
                            );
                          })}
                        </div>
                      </div>
                      <div>
                        <Divider>
                          <h3 style={{ fontWeight: "900" }}>安装教程</h3>
                        </Divider>
                        <div
                          className="lockDockerUrl"
                          onClick={() => {
                            window.open(
                              "https://bndhobett4.feishu.cn/drive/folder/Pvr0ffyxclAdDgdDYmyc0fNxnsf?from=from_copylink"
                            );
                          }}
                        >
                          <Button type="primary">查看教程</Button>
                        </div>
                      </div>
                    </>
                  )}
                </div>
              ) : (
                <div className="titlecontent">
                  <div className="tibtitlecontent">
                    <h1 className="tibtitle">
                      抖羚羊电商工具 有效提高运营效率
                    </h1>
                    <div className="tibtitleconter">
                      专注电商运营插件，协助老板、运营人员高效准确运营
                    </div>
                  </div>
                  <Popover
                    placement="bottom"
                    content={
                      <div style={{ width: "150px" }}>
                        <h3 style={{ textAlign: "center" }}>1.点击下载插件</h3>
                        {/* <img
                style={{ width: "100px", height: "100px" }}
                src={ossbaseUrl + showItemUrlObj.imgUrl}
              /> */}
                        {/* <div>{nowWebIcon.jiaochengcontent}</div> */}
                        <Button
                          type="primary"
                          style={{ marginLeft: "80px" }}
                          onClick={PopNext}
                          size="small"
                        >
                          下一步
                        </Button>
                      </div>
                    }
                    // title=""
                    trigger="click"
                    visible={visibles}
                    // onVisibleChange={handleVisibleChange}
                  >
                    {/* {oss == "windows" ? (
                <Popover
                  content={nowWebIcon.jiaochengcontent}
                  title={nowWebIcon.tittag + " 版插件-安装教程"}
                >
                  <div className="dowiconbox" onClick={winUrl}>
                    <div className="iconbiox">
                      <img
                        src={nowWebIcon.icon_url}
                        className="iconmobxconte"
                        alt=""
                      />
                    </div>
                    <div className="dowtitlecont">{nowWebIcon.tittag}</div>
                    <div className="dowiconbx">
                      <Icon type="DownloadIcon" className="iconmobx"></Icon>
                    </div>
                  </div>
                </Popover>
              ) : (
                <div className="dowIcn" onClick={InstallationDownload}>
                  <div className="dowiconboxs">
                    <div className="iconbiox">
                      <img
                        src={`${ossbaseUrl}logo512.png`}
                        style={{ width: "50px", marginTop: "5px" }}
                        alt="logo512.png"
                      />
                    </div>
                    <div
                      className="dowtitlecont"
                      style={{ fontWeight: "900", letterSpacing: "1px" }}
                    >
                      一键安装使用
                    </div>
                    <div className="dowiconbx">
                      <Icon type="DownloadIcon" className="iconmobx"></Icon>
                    </div>
                  </div>
                  <div className="Out">官方推荐使用</div>
                </div>
              )} */}
                    <div
                      className="dowIcn"
                      onClick={() =>
                        (window.location.href =
                          "https://fxg.jinritemai.com/ffa/g/list")
                      }
                    >
                      <div className="dowiconboxss">
                        <div className="iconbiox">
                          <img
                            src={`${ossbaseUrl}logo512.png`}
                            style={{ width: "50px", marginTop: "5px" }}
                            alt="logo512.png"
                          />
                        </div>
                        <div
                          // className="dowtitlecont"
                          style={{
                            fontWeight: "900",
                            letterSpacing: "1px",
                            fontSize: "24px",
                            marginLeft: "20px",
                          }}
                        >
                          立即去使用
                        </div>
                      </div>
                    </div>
                  </Popover>
                  {/*  */}
                  {/* <Divider>
              <h3 style={{ fontWeight: "900" }}>下载插件</h3>
            </Divider> */}
                  {/* <div className="crolist">
              {iconList.map((item, index) => {
                return (
                  <Popover
                    title={item.tittag + " 版插件-安装教程"}
                    key={index}
                    content={item?.jiaochengcontent}
                  >
                    <div className="intoulist" onClick={() => winUrls(item)}>
                      <div className="iconimagebox">
                        <img
                          src={item.icon_url}
                          className="iconmobxconte"
                          alt=""
                        />
                      </div>
                      <div className="contenttitle">{item.tittag}</div>
                    </div>
                  </Popover>
                );
              })}
            </div> */}
                </div>
              )}{" "}
            </div>
          </div>
        ) : (
          <div>
            <div className="titlecontent">
              <div className="tibtitlecontent">
                <h1 className="tibtitle">抖羚羊电商工具 有效提高运营效率</h1>
                <div className="tibtitleconter">
                  专注电商运营插件，协助老板、运营人员高效准确运营
                </div>
              </div>
            </div>
            <div style={{ height: "195px" }}></div>
          </div>
        )}

        {/* <div style={{}}>
					<div style={{position:'absolute',bottom:"200px",right:'100px',width:'300px',height:'300px',padding:"20px",background:'white'}}>
						<img src="https://wtplugwt.oss-cn-hangzhou.aliyuncs.com/webimages/code/WechatIMG6.jpg" alt="" style={{borderRadius:"20px"}} />
						<h1>客服</h1>
					</div>
				</div> */}

        <div className="funinfobox" id="fundoe">
          <Divider>
            <h3 className="unger">功能演示</h3>
          </Divider>

          <div className="yansgconte">
            {ossFunsImages.map((item, index) => {
              return (
                <div className="funitembox">
                  <div style={{ marginBottom: "5px" }}>
                    <Tabs
                      size="large"
                      activeKey={activeKey}
                      onChange={(key) => alltabs(key, index)}
                    >
                      {tabs.map((item, index) => (
                        <Tabs.TabPane tab={item.name} key={item.value} />
                      ))}
                    </Tabs>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      flexWrap: "wrap",
                    }}
                  >
                    {width < 500 ? (
                      <div style={{ widthL: "10%" }}></div>
                    ) : (
                      <div style={{ widthL: "10%" }}>
                        <div className="steps">
                          {activeKey == "0" && (
                            <div>
                              <Steps current={current} direction="vertical">
                                {step.map((item, index) => (
                                  <Step
                                    title={item.name}
                                    onClick={() => goStep(item, index)}
                                    style={{ cursor: "pointer" }}
                                  />
                                ))}
                              </Steps>
                            </div>
                          )}
                          {activeKey == "1" && (
                            <div className="dynamicScrollbar">
                              <Steps
                                current={currents}
                                direction="vertical"
                                style={{}}
                              >
                                {steps.map((item, index) => (
                                  <Step
                                    title={item.name}
                                    onClick={() => goSteps(item, index)}
                                    style={{ cursor: "pointer" }}
                                  />
                                ))}
                              </Steps>
                            </div>
                          )}
                          {activeKey == "2" && (
                            <div
                              className="dynamicScrollbar"
                              style={{ width: "160px" }}
                            >
                              <Steps current={currentsj} direction="vertical">
                                {stepsj.map((item, index) => (
                                  <Step
                                    title={item.name}
                                    onClick={() => goStepsj(item, index)}
                                    style={{ cursor: "pointer" }}
                                  />
                                ))}
                              </Steps>
                            </div>
                          )}
                          {activeKey == "3" && (
                            <div
                              className="dynamicScrollbar"
                              style={{ width: "160px" }}
                            >
                              <Steps current={currentsj} direction="vertical">
                                {aidoctext.map((item, index) => (
                                  <Step
                                    title={item.name}
                                    onClick={() => goAidocIt(item, index)}
                                    style={{ cursor: "pointer" }}
                                  />
                                ))}
                              </Steps>
                            </div>
                          )}
                        </div>
                      </div>
                    )}
                    <div key={index} className="tabsd">
                      {/* <div className="titinfo">{item.title}</div> */}

                      {width > 500 ? <div></div> : <div></div>}
                      <div style={{ marginBottom: "24px" }} className="titinfo">
                        {activeKey == "0" && (
                          <Carousel
                            autoplay
                            afterChange={(item) => onSwipes(item, index)}
                            ref={carouselRef}
                          >
                            {item.contentItem.map((imageitem, indexs) => {
                              return (
                                <img
                                  src={imageitem.url}
                                  alt=""
                                  onClick={() => {
                                    imgPreview({
                                      defaultIndex: indexs,
                                      imgUrl: item.contentItem.map(
                                        (showmaxcontet, indexcon) => {
                                          return showmaxcontet.url;
                                        }
                                      ),
                                    });
                                  }}
                                  key={indexs}
                                  className="reboticom"
                                />
                              );
                            })}
                          </Carousel>
                        )}
                        {activeKey == "1" && (
                          <div>
                            <Carousel
                              autoplay
                              afterChange={(item) => onSwipess(item, index)}
                              ref={carouselRefs}
                            >
                              {item.contentItems.map((imageitem, indexs) => {
                                return (
                                  <img
                                    src={imageitem.url}
                                    alt=""
                                    onClick={() => {
                                      imgPreview({
                                        defaultIndex: indexs,
                                        imgUrl: item.contentItems.map(
                                          (showmaxcontet, indexcon) => {
                                            return showmaxcontet.url;
                                          }
                                        ),
                                      });
                                    }}
                                    key={indexs}
                                    className="reboticom"
                                  />
                                );
                              })}
                            </Carousel>
                          </div>
                        )}
                        {activeKey == "2" && (
                          <div>
                            <Carousel
                              autoplay
                              afterChange={(item) => onSwipessj(item, index)}
                              ref={carouselRefssj}
                            >
                              {item.contentItemsj.map((imageitem, indexs) => {
                                return (
                                  <img
                                    src={imageitem.url}
                                    alt=""
                                    onClick={() => {
                                      imgPreview({
                                        defaultIndex: indexs,
                                        imgUrl: item.contentItemsj.map(
                                          (showmaxcontet, indexcon) => {
                                            return showmaxcontet.url;
                                          }
                                        ),
                                      });
                                    }}
                                    key={indexs}
                                    className="reboticom"
                                  />
                                );
                              })}
                            </Carousel>
                          </div>
                        )}
                        {activeKey == "3" && (
                          <div>
                            <Carousel
                              autoplay
                              afterChange={(item) => onShowAiDoc(item, index)}
                              ref={aidocRef}
                            >
                              {item.aidoc.map((imageitem, indexs) => {
                                return (
                                  <img
                                    src={imageitem.url}
                                    alt=""
                                    onClick={() => {
                                      imgPreview({
                                        defaultIndex: indexs,
                                        imgUrl: item.aidoc.map(
                                          (showmaxcontet, indexcon) => {
                                            return showmaxcontet.url;
                                          }
                                        ),
                                      });
                                    }}
                                    key={indexs}
                                    className="reboticom"
                                  />
                                );
                              })}
                            </Carousel>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>

        <div className="funinfobox bouyer">
          <Divider>
            <h3 className="unger">联系方式</h3>
          </Divider>
          <div className="cungherjs">
            <Customer />
          </div>
        </div>
      </main>
      <footer className="footerbox">
        <div className="banquan">伟桐科技控股（杭州）有限公司 版权所有</div>
        {/* <div className="banquan">
					客服电话：13456022122
				</div> */}
        <div className="banquan">
          <a href="https://beian.miit.gov.cn/" target="_blank">
            浙ICP备2022033280号-3
          </a>
        </div>
      </footer>
      <Drawer
        visible={showShipinjiaoc}
        keyboard
        maskClosable
        zIndex={99999999999}
        title={showDgtern + (showVideo ? " 视频教程" : " 动画教程")}
        onClose={() => {
          setShowVideo(false);
          setShowShipinjiaoc(false);
        }}
        footer={null}
      >
        <div className="shouwcmye">
          {showVideo ? (
            <video
              className="shipinjiaoc"
              src={ossbaseUrl + showItemUrlObj.videoUrl}
              controls
              autoPlay
            ></video>
          ) : (
            <img
              className="gifbox"
              src={ossbaseUrl + showItemUrlObj.imgUrl}
              alt=""
            />
          )}
        </div>
        <div className="lockVideoBox">
          <Button
            type="primary"
            onClick={() => {
              // setShowShipinjiaoc(true)
              setShowVideo(!showVideo);
            }}
          >
            查看{showVideo ? "动画教程" : "视频教程"}
          </Button>
        </div>
      </Drawer>
      <Modal
        visible={visiblesd}
        title="此浏览器支持多店铺管理"
        footer={[]}
        width={800}
        closable={false}
      >
        <div>
          {/* <img src={`${ossbaseUrl}logo512.png`} alt="logo512.png" /> */}

          <video
            src="https://wtplugwt.oss-cn-hangzhou.aliyuncs.com/webimages/install_doc/vdo/be1943e0873e4780205a56a0bfd2b6ce.mp4"
            style={{ width: "100%" }}
            autoPlay
            controls
            // src={ossbaseUrl + 'install_doc/In_code_vod/%E7%BE%9A%E7%BE%8A.mp4'}
          ></video>
        </div>
        {visiblesds ? (
          <div
            style={{
              float: "right",
              // height: "30px",
              // marginTop: "20px",
            }}
          >
            <Button type="primary" size="small" onClick={handleCancel}>
              关闭
            </Button>
          </div>
        ) : (
          <div style={{ float: "right", height: "30px" }}></div>
        )}
      </Modal>
      <Modal
        visible={visiblesdion}
        // title="防隔离使用说明（必看）"
        footer={[]}
        width={1000}
        bodyBottomMask
        wrapClassName={"sp-main-modal"}
        closable={false}
      >
        {" "}
        <div style={{ color: "red", fontSize: "30px", fontWeight: "900" }}>
          防隔离使用说明（必看）
        </div>
        {/* <Button
          type={defaultValue == "1" ? "primary" : "default"}
          onClick={() => group("1")}
        >
          图文教程
        </Button>
        <Button
          style={{ margin: "0 0 0 10px" }}
          type={defaultValue == "2" ? "primary" : "default"}
          onClick={() => group("2")}
        >
          视频教程
        </Button> */}
        <div></div>
        <div style={{ height: "450px" }}>
          {defaultValue == "1" ? (
            <div>
              <div style={{ margin: "20px 0" }}>
                <Carousel autoplay>
                  {ionlist.map((item, index) => {
                    return (
                      <>
                        <div style={{ fontWeight: "900", fontSize: "20px" }}>
                          {index + 1}.{item.title}
                        </div>

                        <img
                          style={{
                            // width: "840px",
                            margin: "0 auto",
                            height: "400px",
                          }}
                          src={item.url}
                          alt=""
                          onClick={() => {
                            imgPreview({
                              defaultIndex: index,
                              imgUrl: ionlist.map((i, indexcon) => {
                                return i.url;
                              }),
                            });
                          }}
                        />
                      </>
                    );
                  })}
                </Carousel>
              </div>
            </div>
          ) : (
            <div>
              <video
                style={{ width: "950px", margin: "20px auto 0" }}
                src={video}
                controls
                autoPlay
              ></video>
            </div>
          )}{" "}
        </div>
        {fromion == true ? (
          <div style={{ float: "right" }}>
            <Button
              style={{ margin: "0 10px" }}
              type="primary"
              onClick={handleCancels}
            >
              确定
            </Button>
            <Button type="dashed" onClick={handleCancels}>
              取消
            </Button>
          </div>
        ) : (
          <div></div>
        )}
      </Modal>
    </section>
  );
}

export default Home;
